import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const RaynorGaming = () => {

  return (
    <Grid container className={styles.wrapper}>
      <Grid item sm={4} md={4} className={styles.decorative_img}>
        <img src="./assets/images/raynorgroup/chair2.png" alt=""/>
      </Grid>

      <Grid item sm={6} md={4} className={styles.text_content}>
        <Typography className={styles.title}>
          Raynor Gaming gives you next-level innovation
          with the most advanced
          gaming chairs around.
        </Typography>

          <section className={styles.description_container}>
              <Typography className={styles.description}>
                  Only Raynor Gaming chairs feature Outlast technology. Outlast
                  proactively eliminates perspiration and stores excess body heat,
                  keeping you cool and comfortable.
              </Typography>
          </section>

      </Grid>

      <Grid item sm={6} md={4} className={styles.bg}>
        <img src="./assets/images/raynorgroup/raynor_gaming.png" alt=""/>
      </Grid>
    </Grid>
  )
}
