import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const RaynorGamingMobile = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} className={styles.text_content}>
        <Typography className={styles.title}>
          Raynor Gaming gives you next-level innovation
          with the most advanced
          gaming chairs around.
        </Typography>

        <Typography className={styles.description}>
          Only Raynor Gaming chairs feature Outlast technology. Outlast
          proactively eliminates perspiration and stores excess body heat,
          keeping you cool and comfortable.
        </Typography>

      </Grid>

      <Grid item xs={12} className={styles.image_wrapper}>
        <img src="./assets/images/raynorgroup/raynorgaming_bg_mobile.png" className={styles.img}/>
      </Grid>

      <Grid item xs={12}>
        <FooterComponent show={true} />
      </Grid>
    </Grid>
  )
}
