import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const WoodFord = () => {
  return (
    <Grid container className={styles.wrapper}>

      <Grid item sm={6} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          Inspired by Bourbon’s History
        </Typography>

        <section className={styles.description_container}>
          <Typography className={styles.description}>
            The art of making fine bourbon first took place on the site of the Woodford Reserve Distillery, a
            National Historic Landmark, in 1812.
            You get that sense of history. The history of bourbon is the history
            of Kentucky, is the history of the United States.
          </Typography>
        </section>

      </Grid>

      <Grid item sm={6} md={6} className={styles.bg}>
        <img src="./assets/images/raynorgroup/woodford_bg.png" alt=""/>
      </Grid>
    </Grid>
  )
}
