import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Tempur = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item sm={6} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          TEMPUR®
        </Typography>

        <section className={styles.description_container}>
          <Typography className={styles.description}>
            TEMPUR® technology precisely adapts to your weight, shape and temperature — offering unmatched comfort and
            support.
            For more than 25 years, TEMPUR® Material has evolved to give you a sleep experience like no other.
            Our material — unlike memory foam — is uniquely formulated to respond to your body’s weight, shape and
            temperature.
            The viscous (fluid-like) and elastic (spring-like) properties of the material actively work together — in
            the right way, at the right places to provide the right support, comfort and infinite adaptability.
            The result? Unparalleled pressure point relief, absorption, and motion separation.. That’s why no other
            material performs like TEMPUR® Material.
          </Typography>
        </section>

      </Grid>

      <Grid item sm={6} md={6} className={styles.bg}>
        <img src="./assets/images/raynorgroup/tempur_bg.png" alt=""/>
      </Grid>
    </Grid>
  )
}
