import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const ReaynorTabComponent = () => {
    return (
        <Grid container direction="column" className={styles.wrapper}>

            <Grid item xs={12} className={styles.slide_container}>
                <Grid item md={6} className={styles.text_content}>
                    <Typography className={styles.title}>
                        We Stand For The Sitting
                    </Typography>

                    <Typography className={styles.description}>
                        From initial designs through final production, our team is committed to delivering innovative
                        technology, superior craftsmanship and design along with ergonomic comfort in every chair we make.
                    </Typography>
                </Grid>
                <Grid item md={6} className={styles.img_wrapper}>
                    <img src="/assets/images/raynorgroup/slide1_bg.png" className={styles.img}/>
                </Grid>
            </Grid>

            <Grid item xs={12} className={styles.slide_container}>
                <Grid item md={4} className={styles.text_content}>
                    <Typography className={styles.title}>
                        Since 1979, the Raynor Group
                        has represented quality and unsurpassed customer service
                        in office seating under the
                        Eurotech name.
                    </Typography>

                    <Typography className={styles.description}>
                        Today, the Raynor Group is responsible for manufacturing, marketing,
                        sales and distribution for a select group of office seating products that
                        are manufactured and sold worldwide.
                    </Typography>

                </Grid>

                <Grid item md={4} className={styles.img_wrapper}>
                    <img src="/assets/images/raynorgroup/slide2_bg_mobile.png" className={styles.img}/>
                </Grid>
            </Grid>

            <Grid item xs={12} className={`${styles.slide_container} ${styles.dark_slide}`}>

                <Grid item md={5} className={styles.text_content}>
                    <Typography className={styles.title}>
                        Since 1979, the Raynor Group
                        has represented quality and unsurpassed customer service
                        in office seating under the
                        Eurotech name.
                    </Typography>

                    <Typography className={styles.description}>
                        Today, the Raynor Group is responsible for manufacturing, marketing,
                        sales and distribution for a select group of office seating products that
                        are manufactured and sold worldwide.
                    </Typography>
                </Grid>

                {/*<Grid item md={5} className={styles.img_wrapper}>*/}
                {/*    <img src="/assets/images/raynorgroup/slide3_bg_mobile.png" className={styles.img}/>*/}
                {/*</Grid>*/}

            </Grid>

            <Grid item xs={12} className={styles.slide_container}>

                <Grid item md={5} className={styles.text_content}>
                    <Typography className={styles.title}>
                        The Raynor Group focuses
                        on a limited number of retail customers in order to provide customized solutions that completely
                        meet your needs.
                    </Typography>

                    <Typography className={styles.description}>
                        With cumulative sales of over $1 billion, the Raynor Group is one of the most successful
                        manufacturing, marketing and distribution companies
                        in the Uunited States.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={styles.statistic_wrapper}>
                    <Grid>
                        <Grid container className={styles.statistic_item}>
                            <Grid item xs={12} className={styles.number}>
                                $1b
                            </Grid>
                            <Grid item xs={12} className={styles.stat_text}>
                                Cumulative sales of over $1 billion
                            </Grid>
                        </Grid>
                        <Grid container className={styles.statistic_item}>
                            <Grid item xs={12} className={styles.number}>
                                30+
                            </Grid>
                            <Grid item xs={12} className={styles.stat_text}>
                                Over 30 years of experience
                            </Grid>
                        </Grid>
                        {/*<Grid container className={styles.statistic_item}>*/}
                        {/*    <Grid item xs={12} className={styles.number}>*/}
                        {/*        240*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} className={styles.stat_text}>*/}
                        {/*        Lorem ipsum dolor sit amet*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <FooterComponent show={true} />
            </Grid>
        </Grid>
    )
}