import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const ComfortRevolution = () => {
  return(
    <Grid container className={styles.wrapper}>

      <Grid item md={6} className={styles.text_content}>
        <Typography className={styles.title}>
            Comfort Reimagined
        </Typography>

        <Typography className={styles.description}>
            The Comfort Revolution culture is centered around new ideas, new materials, and new solutions.
            We constantly seek revolutionary ways to apply comfort to new areas of people’s lives. Pair that with
            our commitment of offering the very best for less, and you start to see how Comfort Revolution is
            redefining comfort. All this is possible because the people at Comfort Revolution live our mantra of
            pushing boundaries - where some see ordinary, we see a new way. The spark of an idea. An opportunity.
            When you take passionate people who see things differently and put them together, you get a revolution.
        </Typography>

      </Grid>

      <Grid item md={6} className={styles.image_wrapper}>
        <img src="./assets/images/raynorgroup/tabs/comfort/comfort_img.png" className={styles.img}/>
      </Grid>

      <Grid item xs={12}>
        <FooterComponent show={true} />
      </Grid>

    </Grid>
  )
}
