import React from "react";
import styles from './styles.module.scss';
import {Grid} from "@material-ui/core";
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const NbaLeague = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item md={6} className={styles.text_content}>
        <div className={styles.title}>
            NBA 2K League
        </div>

        <div className={styles.description}>
            The NBA 2K League, a joint venture between the NBA and Take-Two Interactive, is a professional
            esports league featuring the best NBA 2K players in the world and the first official esports league
            operated by a U.S. professional sports league.  NBA 2K League content has generated more than 415 million
            video views across all NBA and NBA 2K League social media platforms. The NBA 2K League and its teams have
            more than 2M million combined followers on social media platforms.
        </div>

      </Grid>

      <Grid item md={6} className={styles.image_wrapper}>
        <img src="./assets/images/raynorgroup/tabs/nba/nba_img.png" className={styles.img}/>
      </Grid>

      <Grid item xs={12}>
        <FooterComponent show={true} />
      </Grid>
    </Grid>
  )
}
