import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";

export const Slide_4 = ( ) => {
  return (
    <Grid container className={styles.slide_container}>

      <Grid item sm={7} md={6} className={styles.statistic_wrapper}>
        <Grid>
          <Grid container className={styles.statistic_item}>
            <Grid item sm={4} md={4} className={styles.number}>
              $1b
            </Grid>
            <Grid item sm={8} md={8} className={styles.stat_text}>
              Cumulative sales of over $1 billion
            </Grid>
          </Grid>
          <Grid container className={styles.statistic_item}>
            <Grid item sm={4} md={4} className={styles.number}>
              30+
            </Grid>
            <Grid item sm={8} md={8} className={styles.stat_text}>
              Over 30 years of experience
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={5} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          The Raynor Group focuses
          on a limited number of retail customers in order to provide customized solutions that completely
          meet your needs.
        </Typography>

        <Typography className={styles.description}>
          With cumulative sales of over $1 billion, the Raynor Group is one of the most successful
          manufacturing, marketing and distribution companies
          in the United States.
        </Typography>
      </Grid>

    </Grid>
  )
}
