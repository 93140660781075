import React from "react";
import styles from './styles.module.scss';
import {Grid} from "@material-ui/core";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Elizabeth = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item sm={6} md={6} className={styles.decorative_img}>
        <img src="" alt=""/>
      </Grid>

      <Grid item sm={6} md={6} className={styles.text_content}>
        <div className={styles.title}>
          Elizabeth Sutton<br/>
          Collection
        </div>

        <section className={styles.description_container}>
          <div className={styles.description}>
            Elizabeth Sutton (b. 1989, New York, NY) is a millennial “mom-preneur” and visual artist and designer who
            has taken the nouveau arts scene by storm. In 2016, Elizabeth was selected to participate in the NYDesigns
            incubator program, exhibited at both the Affordable Art Fair & Hamptons Designer Showhouse, and was featured
            by Bravo’s “Million Dollar Listing.” Her work has been featured in Entrepreneur, Arch Digest, Arch Digest
            Spain, The New York Post, Time Out Magazine, Haute Living, The Wall Street Journal, The New York Times,
            and more.
            <br/>
            <br/>
            Elizabeth Sutton Collection is an NYC based highly curated fine art and design firm. Team ESC’s goal is
            to foster positivity and good vibes through Sutton’s colorful artworks and products.
          </div>
        </section>

      </Grid>
    </Grid>
  )
}
