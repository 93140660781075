import React from 'react';
import styles from './app.module.scss';
import {RaynorPage} from "./pages/raynor";
import {HeaderComponent} from "./components/header";

const App: React.FC = () => (
    <main className={styles.main}>
      <RaynorPage />
    </main>
);

export default App;
