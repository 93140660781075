import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const TempurModile = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          TEMPUR® materials provide unparalleled pressure relief and motion cancellation.
        </Typography>

        <Typography className={styles.description}>
          Millions of satisfied owners get their best night’s sleep on a Tempur-Pedic® mattress. And now, that
          technology is available in an office chair, exclusively from Raynor Group. The one-of-a-kind TEMPUR®
          material delivers personalized comfort and support and is an outstanding complement to Raynor’s world
          class ergonomics and design.
        </Typography>

        <a className={global_styles.bttn}>
          Visit Website
          <div className={global_styles.right_arrow}>
            <ChevronRightIcon style={{color: '#D13532', fontSize: 17}}/>
          </div>
        </a>
      </Grid>

      <Grid item md={6} className={styles.image_wrapper}>
        <img src="./assets/images/raynorgroup/tempur_bg.png" className={styles.img}/>
      </Grid>

      <Grid item xs={12}>
        <FooterComponent show={true} />
      </Grid>
    </Grid>
  )
}
