import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const SharperTab = () => {
  return(
    <Grid container className={styles.wrapper}>

      <Grid item sm={7} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          Sharper Image
        </Typography>

        <section className={styles.description_container}>
          <Typography className={styles.description}>
            Founded in 1977, Sharper Image is best known for its one-of-a-kind, innovative products offered across the
            lifestyle, gift, gadget, electronics, wellness, fitness and travel categories. Sharper Image products are
            sold nationwide through a variety of channels, including at better department and specialty stores and
            direct to consumer. Sharper Image is synonymous with innovation and unique, ’giftable’ product designs
            offered across a wide variety of consumer product categories.
          </Typography>
        </section>

      </Grid>

      <Grid item sm={5} md={6} className={styles.bg}>
        <img src="./assets/images/raynorgroup/tabs/sharper/sharper_img.png" alt=""/>
      </Grid>

    </Grid>
  )
}
