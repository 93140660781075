import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FooterComponent} from "../../../../../components/footer";

export const OutlastMobile = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          Experience the only office chair with space age,
          cooling technology.
        </Typography>

        <Typography className={styles.description}>
          Outlast® technology, originally developed for NASA, utilizes phase change materials that absorb, store
          and release heat for optimal
          thermal comfort.
        </Typography>

      </Grid>

      <Grid item md={6} className={styles.img_wrapper}>
        <img src="/assets/images/raynorgroup/outlast.png" className={styles.img}/>
      </Grid>

      <Grid item xs={12}>
        <FooterComponent show={true} />
      </Grid>

    </Grid>
  )
}
