import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Slide_2 = ( ) => {
  return (
    <Grid container className={styles.slide_container}>

      <Grid item sm={4} md={4} className={styles.img_wrapper}>
        <img src="" className={styles.img}/>
      </Grid>

      <Grid item sm={4} md={5} className={styles.slide_2_text}>
        <Typography className={styles.title}>
          Since 1979, the Raynor Group
          has represented quality and unsurpassed customer service
          in office seating under the
          Eurotech name.
        </Typography>

        <Typography className={styles.description}>
          Today, the Raynor Group is responsible for manufacturing, marketing,
          sales and distribution for a select group of office seating products that
          are manufactured and sold worldwide.
        </Typography>

        {/*<a className={global_styles.bttn}>*/}
        {/*  Visit Website*/}
        {/*  <div className={global_styles.right_arrow}>*/}
        {/*    <ChevronRightIcon style={{color: '#D13532', fontSize: 17}}/>*/}
        {/*  </div>*/}
        {/*</a>*/}
      </Grid>

      <Grid item sm={4} md={3} className={styles.img_riht}>
        <img src="/assets/images/raynorgroup/chair1.png" className={styles.img}/>
      </Grid>

    </Grid>
  )
}
