import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";

export const Slide_3 = ( ) => {
  return (
    <Grid container className={`${styles.slide_container} ${styles.dark_slide}`}>

      <Grid item sm={1} md={4} className={styles.sketch}>
        <img src="/assets/images/raynorgroup/sketch.png" className={styles.img}/>
      </Grid>

      <Grid item sm={5} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          Since 1979, the Raynor Group
          has represented quality and unsurpassed customer service
          in office seating under the
          Eurotech name.
        </Typography>

        <Typography className={styles.description}>
          Today, the Raynor Group is responsible for manufacturing, marketing,
          sales and distribution for a select group of office seating products that
          are manufactured and sold worldwide.
        </Typography>
      </Grid>
    </Grid>
  )
}
