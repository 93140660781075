import React from "react";
import styles from './styles.module.scss';
import {Grid} from "@material-ui/core";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const WorkPro = () => {
  return (
    <Grid container className={styles.wrapper}>

      <Grid item sm={6} md={6} className={styles.decorative_img}>
        <img src="./assets/images/raynorgroup/workpro_bg.png" alt=""/>
      </Grid>

      <Grid item sm={6} md={6} className={styles.text_content}>
        <div className={styles.title}>
          Ergonomic series engineered for every kind of work.
        </div>

        <section className={styles.description_container}>
          <div className={styles.description}>
            We engineer our office chairs using cutting edge seating technology to support every inch of the body,
            keeping you, your team, and your guests comfortable during even the longest days. Work is
            unpredictable, so we designed our WorkPro series to handle the day’s twists and turns as
            professionally as you and your team.
          </div>
        </section>

      </Grid>
    </Grid>
  )
}
