import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";

interface IFooter {
    show: boolean
}

export const FooterComponent: React.FC<IFooter> = ({show}) => {
    return (
        <Grid container className={show ? `${styles.wrapper}` : `${styles.wrapper_down}`}>

            <Grid item xs={12} sm={4} md={4} className={styles.container}>
                <Typography className={styles.text}>
                    525 Hempstead Turnpike, W. Hempstead, NY 11552
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} className={styles.container_img}>
                <Typography className={styles.image}>
                    <img src="/assets/images/raynorgroup/rayanor_logo_new.png" alt=""/>
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} className={styles.container}>
                <Typography className={styles.text}>
                    © 2020, The Raynor Group Companies. All rights reserved.
                </Typography>
            </Grid>

        </Grid>
    )
}