import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Outlast = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item sm={6} md={6} className={styles.img_wrapper}>
        <img src="" className={styles.img}/>
      </Grid>

      <Grid item sm={6} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          OUTLAST ®
        </Typography>

        <section className={styles.description_container}>
          <Typography className={styles.description}>
            Outlast® technology, originally developed for NASA, utilizes phase change materials (PCM) that absorb,
            store and release heat for optimal thermal comfort. Outlast® technology is comparable to ice in a drink;
            as it changes from solid to liquid, it absorbs heat and cools the drink, keeping that drink at the desired
            temperature for longer. Outlast® phase change materials work in the same way, but are microencapsulated to
            be
            permanently enclosed and protected in a polymer shell. We call microencapsulated phase change materials
            Thermocules™.
            <br/>
            <br/>
            This encapsulation process makes the Thermocules™ exceptionally durable for many applications. These
            Thermocules™ can be incorporated into fabrics and fibers and have the capacity to absorb, store and release
            excess heat. This gives any product containing Outlast® technology the ability to continually regulate
            skin’s
            microclimate. As the skin gets hot, the heat is absorbed, and as it cools, that heat is released.
          </Typography>
        </section>

      </Grid>
    </Grid>
  )
}
