import React from "react";
import styles from './styles.module.scss';
import {Grid, Typography} from "@material-ui/core";
import global_styles from "../../../../app.module.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Slide_1 = ( ) => {
  return (
    <Grid container className={styles.slide_container}>
      <Grid item sm={6} md={6} className={styles.img_wrapper}>
        <img src="" className={styles.img}/>
      </Grid>

      <Grid item sm={6} md={6} className={styles.text_content}>
        <Typography className={styles.title}>
          We Stand For The Sitting
        </Typography>

        <Typography className={styles.description}>
          From initial designs through final production, our team is committed to delivering innovative
          technology, superior craftsmanship and design along with ergonomic comfort in every chair we make.
        </Typography>

        {/*<a className={global_styles.bttn}>*/}
        {/*  Visit Website*/}
        {/*  <div className={global_styles.right_arrow}>*/}
        {/*    <ChevronRightIcon style={{color: '#D13532', fontSize: 17}}/>*/}
        {/*  </div>*/}
        {/*</a>*/}
      </Grid>
    </Grid>
  )
}
